import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      redirectAfterAuth: true
    }
  },
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/rooms",
    name: "rooms-list",
    component: () => import("../views/rooms/List.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/amenities",
    name: "amenities",
    component: () => import("../views/rooms/Amenitites.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/prices",
    name: "prices",
    component: () => import("../views/rooms/Prices.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/screencontrol",
    name: "screencontrol",
    component: () => import("../views/rooms/ScreenControl.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/seatingdisplay",
    name: "seatingdisplay",
    component: () => import("../views/rooms/SeatingDisplay.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/rooms/:id",
    name: "rooms-edit",
    component: () => import("../views/rooms/Edit.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/addons",
    name: "addons",
    component: () => import("../views/rooms/addons/List.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/addons/:id",
    name: "addons-edit",
    component: () => import("../views/rooms/addons/Edit.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tenants",
    name: "tenants-list",
    component: () => import("../views/tenants/List.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tenants/:id",
    name: "tenants-edit",
    component: () => import("../views/tenants/Edit.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/import",
    name: "import",
    component: () => import("../views/users/Import.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/export",
    name: "export",
    component: () => import("../views/users/Export.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users",
    name: "users-list",
    component: () => import("../views/users/List.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users/:id",
    name: "users-edit",
    component: () => import("../views/users/Edit.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/catering",
    name: "catering",
    component: () => import("../views/caterings/List.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/catering/products",
    name: "catering-products",
    component: () => import("../views/caterings/Products.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/catering/categories",
    name: "catering-categories",
    component: () => import("../views/caterings/Categories.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/bookings",
    name: "bookings-list",
    component: () => import("../views/bookings/List.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/bookings/:id",
    name: "bookings-edit",
    component: () => import("../views/bookings/Edit.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/accounting",
    name: "accounting",
    component: () => import("../views/Accounting.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/Settings.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/logs/:module",
    name: "logs",
    component: () => import("../views/logs/List.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/no-access",
    name: "no-access",
    component: () => import("../views/NoAccess.vue"),
    meta: {
      requiresAuth: false,
      isFullscreen: true
    }
  },
  {
    path: "*",
    name: "not-found",
    component: () => import("../views/NotFound.vue"),
    meta: {
      requiresAuth: false,
      isFullscreen: true
    }
  }
];

export default routes;
