<template>
  <div class="dashboard mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col cols="9">
          <v-row class="mb-3">
            <v-col cols="4" class="pt-0">
              <div class="dashboard-card" v-if="keyFigures.daily_revenue">
                <div class="dashboard-card-icon dashboard-gradient-primary">
                  <v-icon dark x-large class="dc-icon">attach_money</v-icon>
                </div>
                <div class="dashboard-card-content grey lighten-2">
                  <div class="dc-text">
                    <h2>
                      CHF {{ keyFigures.daily_revenue.value | formatCurrency }}
                    </h2>
                    <h6>{{ keyFigures.daily_revenue.title }}</h6>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="4" class="pt-0">
              <div class="dashboard-card" v-if="keyFigures.active_users">
                <div class="dashboard-card-icon dashboard-gradient-accent">
                  <v-icon dark x-large class="dc-icon">account_circle</v-icon>
                </div>
                <div class="dashboard-card-content grey lighten-2">
                  <div class="dc-text">
                    <h2>{{ keyFigures.active_users.value }}</h2>
                    <h6>{{ keyFigures.active_users.title }}</h6>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="4" class="pt-0">
              <div class="dashboard-card" v-if="keyFigures.room_of_the_month">
                <div class="dashboard-card-icon dashboard-gradient-secondary">
                  <v-icon dark x-large class="dc-icon">meeting_room</v-icon>
                </div>
                <div class="dashboard-card-content grey lighten-2">
                  <div class="dc-text">
                    <h2>{{ keyFigures.room_of_the_month.value }}</h2>
                    <h6>{{ keyFigures.room_of_the_month.title }}</h6>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-card color="default" outlined class="elevation-0 chart-card">
            <weekly-bookings-chart v-model="date" />
          </v-card>
        </v-col>
        <v-col cols="3">
          <date-picker v-model="date" outlined />
          <v-card color="default" outlined class="elevation-0 chart-card mb-6">
            <daily-rooms-chart v-model="date" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "@/components/forms/DatePicker";
import WeeklyBookingsChart from "@/components/charts/WeeklyBookingsChart";
import DailyRoomsChart from "@/components/charts/DailyRoomsChart";

export default {
  name: "dashboard",

  components: {
    DatePicker,
    WeeklyBookingsChart,
    DailyRoomsChart
  },

  data: () => ({
    keyFigures: {},
    date: moment()
  }),

  async created() {
    const response = await this.fetchData();
    this.keyFigures = response.data;
  },

  methods: {
    /**
     * Fetch data from server.
     */
    fetchData() {
      return this.$api.http.get(
        `backoffice/statistics/key-figures?date=${this.date.format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }
};
</script>

<style lang="scss">
.dashboard {
  .chart-card {
    border-radius: 0px !important;
  }

  .dashboard-card {
    display: flex;
    flex-direction: row;
    min-height: 100px;

    .dashboard-card-icon {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 30%;
      text-align: center;
      position: relative;

      &.dashboard-gradient-secondary {
        background: radial-gradient(
          circle,
          var(--v-secondary-base),
          var(--v-secondary-darken1)
        );
      }

      &.dashboard-gradient-accent {
        background: radial-gradient(
          circle,
          var(--v-accent-base),
          var(--v-accent-darken1)
        );
      }

      &.dashboard-gradient-primary {
        background: radial-gradient(
          circle,
          var(--v-primary-base),
          var(--v-primary-darken1)
        );
      }

      .dc-icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .dashboard-card-content {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 70%;
      text-align: center;
      position: relative;

      .dc-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
