import Vue from "vue";

Vue.filter("formatCurrency", function(value) {
  let parsedNumber = 0;

  if ((parsedNumber = parseFloat(value))) {
    return (parsedNumber / 100).toFixed(2);
  }

  return parsedNumber.toFixed(2);
});

Vue.filter("formatHighNumber", function(value) {
  return value.toLocaleString("de-CH");
});
