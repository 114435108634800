import store from "../store/index";

class Permissions {
  /**
   * Create a new Api object
   */
  constructor() {
    this.store = store;
  }

  /**
   * Check if the user has permission to a certain module.
   */
  hasPermission(feature) {
    // User is not logged in.
    if (!this.store.state.user.user) {
      return false;
    }

    const scopes = this.store.state.user.user.scopes;

    // Admins have full power.
    if (scopes.includes("admin")) {
      return true;
    }

    switch (feature) {
      case "dashboard":
        return scopes.includes("backoffice-basic");

      case "rooms":
        return scopes.includes("backoffice-rooms");

      case "caterings":
        return scopes.includes("backoffice-caterings");

      case "users":
        return scopes.includes("backoffice-users");

      case "bookings":
        return scopes.includes("backoffice-bookings");

      case "settings":
      case "logs":
        return false;

      default:
        return false;
    }
  }

  /**
   * Check if user is logged in.
   */
  isLoggedIn() {
    return this.store.store.user.user;
  }
}

export default Permissions;
