<template>
  <div class="weekly-bookings-chart">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <template v-else>
      <v-row>
        <v-col class="pb-0 pt-0">
          <h3 class="text-center grey--text text--darken-3">
            Verlauf der letzten 7 Tage
          </h3>
        </v-col>
      </v-row>
      <line-chart
        v-if="datacollection.datasets"
        :chart-data="datacollection"
        :options="options"
      ></line-chart>
      <v-alert v-else dense outlined type="info" class="mt-3 mb-0">
        Keine Daten vorhanden.
      </v-alert>
    </template>
  </div>
</template>

<script>
import LineChart from "./LineChart.js";
import getColorAtIndex from "./chartColors";

export default {
  components: {
    LineChart
  },

  props: ["value"],

  data: () => ({
    loading: false,
    datacollection: {},
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: [
          {
            ticks: {
              suggestedMin: 0
            }
          }
        ]
      }
    }
  }),

  watch: {
    value() {
      this.fillData();
    }
  },

  mounted() {
    this.fillData();
  },

  methods: {
    async fillData() {
      this.loading = true;
      const { data } = await this.fetchData();
      this.datacollection =
        data.length > 0
          ? {
              labels: Object.values(data[0].values).map(day => day.name),
              datasets: data.map((room, index) => ({
                label: room.name,
                data: Object.values(room.values).map(day => day.value),
                backgroundColor: [getColorAtIndex(index)],
                borderWidth: 0
              }))
            }
          : [];
      this.loading = false;
    },

    /**
     * Fetch data from server.
     */
    fetchData() {
      return this.$api.http.get(
        `backoffice/statistics/weekly-bookings?date=${this.value.format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }
};
</script>

<style>
.weekly-bookings-chart {
  padding: 20px;
}
</style>
