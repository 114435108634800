<template>
  <div class="daily-languages-chart" v-if="datacollection.datasets">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <template v-else>
      <v-row>
        <v-col class="pb-3 pt-0">
          <h3 class="text-center grey--text text--darken-3">
            Tagesauslastung (Stunden)
          </h3>
        </v-col>
      </v-row>
      <pie-chart
        v-if="datacollection.datasets[0].data.length > 0"
        :chart-data="datacollection"
        :options="options"
      ></pie-chart>
      <v-alert v-else dense outlined type="info" class="mb-0">
        Keine Daten vorhanden.
      </v-alert>
    </template>
  </div>
</template>

<script>
import PieChart from "./PieChart.js";
import getColorAtIndex from "./chartColors";

export default {
  components: {
    PieChart
  },

  props: ["value"],

  data: () => ({
    loading: false,
    datacollection: {},
    options: { responsive: true, maintainAspectRatio: false }
  }),

  watch: {
    value() {
      this.fillData();
    }
  },

  mounted() {
    this.fillData();
  },

  methods: {
    async fillData() {
      this.loading = true;
      const { data } = await this.fetchData();
      this.datacollection = {
        labels: data.map(room => room.name),
        datasets: [
          {
            label: "Gebucht in Stunden",
            data: data.map(room => room.hours),
            backgroundColor: data.map((item, index) => getColorAtIndex(index)),
            borderWidth: 0
          }
        ]
      };
      this.loading = false;
    },

    /**
     * Fetch data from server.
     */
    fetchData() {
      return this.$api.http.get(
        `backoffice/statistics/daily-rooms?date=${this.value.format(
          "YYYY-MM-DD"
        )}`
      );
    }
  }
};
</script>

<style>
.daily-languages-chart {
  padding: 20px;
}
</style>
