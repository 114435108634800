<template>
  <v-navigation-drawer
    v-model="navigationDrawer"
    :clipped="false"
    :mini-variant.sync="navigationMini"
    mini-variant-width="56"
    permanent
    app
    dark
    v-if="isLoggedIn && !isFullscreen"
    class="app-navigation-drawer"
  >
    <div class="navigation-drawer-header">
      <!-- <v-app-bar-nav-icon
        @click.stop="updateNavigationDrawer($vuetify.breakpoint)"
      /> -->
      <div class="navigation-drawer-header-title">
        <img src="@/assets/T_RoomManagement_White.svg" class="logo" />
      </div>
    </div>
    <v-list dense>
      <template v-for="(item, index) in items">
        <template v-if="item.isVisible && item.isVisible()">
          <!-- Group -->
          <v-list-group
            v-if="item.group"
            :prepend-icon="item.icon"
            :key="index"
            :active-class="'none'"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </template>
            <app-navigation-item
              :subitem="true"
              v-for="(subitem, index) in item.items"
              :item="subitem"
              :key="'s' + index"
            />
          </v-list-group>

          <!-- Navigation item -->
          <app-navigation-item v-else :item="item" :key="item.title" />
        </template>
      </template>
    </v-list>
    <div class="navigation-drawer-footer font-weight-bold">
      <div class="ml-3">
        RoomManagement&nbsp;
        <span class="font-weight-light">System</span>
      </div>
      <div class="ml-3">
        {{ version }}
        <span class="font-weight-light">/ &copy; Tingo GmbH</span>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import AppNavigationItem from "@/components/app/AppNavigationItem";
import BadgesDialog from "@/views/dialogs/BadgesDialog";

export default {
  components: {
    AppNavigationItem
  },

  computed: {
    ...mapState("app", ["drawer", "mini", "version"]),
    ...mapGetters("user", ["isLoggedIn"]),

    items() {
      let items = [
        {
          title: "Dashboard",
          link: "/",
          icon: "dashboard",
          isVisible: () => {
            return true;
          }
        },
        {
          title: "Räume",
          group: true,
          icon: "meeting_room",
          isVisible: () => {
            return this.$permissions.hasPermission("rooms");
          },
          items: [
            {
              title: "Verwalten",
              link: "/rooms",
              icon: ""
            },
            {
              title: "Add-Ons",
              link: "/addons",
              icon: ""
            },
            {
              title: "Ausstattungen",
              link: "/amenities",
              icon: ""
            },
            {
              title: "Preismatrix",
              link: "/prices",
              icon: ""
            },
            {
              title: "Screen Control",
              link: "/screencontrol",
              icon: ""
            },
            {
              title: "Bestuhlungsanzeige",
              link: "/seatingdisplay",
              icon: ""
            }
          ]
        },
        {
          title: "Catering",
          group: true,
          icon: "restaurant",
          isVisible: () => {
            return this.$permissions.hasPermission("caterings");
          },
          items: [
            {
              title: "Produkte",
              link: "/catering/products",
              icon: ""
            },
            {
              title: "Kategorien",
              link: "/catering/categories",
              icon: ""
            }
          ]
        },
        {
          title: "Benutzer",
          group: true,
          icon: "supervisor_account",
          isVisible: () => {
            return this.$permissions.hasPermission("users");
          },
          items: [
            {
              title: "Verwalten",
              link: "/users",
              icon: ""
            },
            {
              title: "Mandanten",
              link: "/tenants",
              icon: ""
            },
            {
              title: "Badges",
              action: () => {
                this.setFullscreenDialogComponent(BadgesDialog);
                this.setFullscreenDialogConfig({ activeBadge: null });
              }
            },
            {
              title: "Import",
              link: "/import",
              icon: ""
            },
            {
              title: "Export",
              link: "/export",
              icon: ""
            }
          ]
        },
        {
          title: "Buchungen",
          group: true,
          icon: "shopping_cart",
          isVisible: () => {
            return this.$permissions.hasPermission("bookings");
          },
          items: [
            {
              title: "Ansehen",
              link: "/bookings",
              icon: ""
            },
            {
              title: "Abrechnungen",
              link: "/accounting",
              icon: ""
            }
          ]
        }
      ];

      return items;
    },

    /**
     * Internal getter / setter
     */
    navigationDrawer: {
      get() {
        return this.drawer;
      },
      set(value) {
        this.setDrawer(value);
      }
    },

    /**
     * Internal getter / setter
     */
    navigationMini: {
      get() {
        return this.mini;
      },
      set(value) {
        // Otherwise an error is thrown.
        if (value) {
          this.setMini(value);
        }
      }
    },

    isFullscreen() {
      return this.$route.meta.isFullscreen ?? false;
    }
  },

  methods: {
    ...mapMutations("app", [
      "initNavigationDrawer",
      "setDrawer",
      "updateNavigationDrawer",
      "setFullscreenDialogComponent",
      "setFullscreenDialogConfig"
    ])
  },

  created() {
    // Set navigation drawer on page load
    this.initNavigationDrawer(this.$vuetify.breakpoint);
  }
};
</script>

<style lang="scss">
.v-navigation-drawer--mini-variant {
  width: 56px !important;

  .v-subheader {
    visibility: hidden;
  }

  .navigation-drawer-header-title,
  .navigation-drawer-footer {
    display: none !important;
  }
}

.v-navigation-drawer .v-list {
  padding-top: 40px;
}

.app-navigation-drawer {
  background: linear-gradient(180deg, #202b2b, #091d1d) !important;

  .v-navigation-drawer__border {
    display: none;
  }

  .navigation-drawer-header {
    padding: 15px 9px;
    height: 64px;
    background-color: #091d1d !important;

    .theme--dark.v-btn.v-btn--icon {
      color: white;
    }

    .navigation-drawer-header-title {
      display: inline-flex;
      color: white;
      vertical-align: middle;
      font-size: 1.3em;
      padding-left: 27px;

      .logo {
        width: 200px;
        margin-left: -24px;
        margin-top: -4px;
      }
    }
  }

  .v-list {
    .theme--dark.v-icon,
    .v-list-item__title {
      color: white;
    }

    .v-list-item--active {
      .theme--dark.v-icon,
      .v-list-item__title {
        color: white;
      }
    }
  }

  .navigation-drawer-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    color: white;
    padding: 10px 0;
    font-size: 0.8em;
    border-top: 1px solid #344242 !important;
  }
}
</style>
