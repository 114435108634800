const app = {
  namespaced: true,

  state: {
    drawer: false,
    mini: false,
    version: null,
    configs: null,
    initialized: false,
    overlayComponent: null,
    fullscreenDialogComponent: null,
    fullscreenDialogConfig: {},
    fullscreenDialogComponentState: 0
  },

  getters: {},

  mutations: {
    /**
     * Set drawer.
     * @param {*} state
     * @param {*} value
     */
    setDrawer(state, value) {
      state.drawer = value;
    },

    /**
     * Set mini.
     * @param {*} state
     * @param {*} value
     */
    setMini(state, value) {
      state.mini = value;
    },

    /**
     * Initialize navigation drawer based on current resolution.
     * @param {*} state
     * @param {*} breakpoint
     */
    initNavigationDrawer(state, breakpoint) {
      state.drawer = breakpoint.lgAndUp ? true : false;
      state.mini = false;
    },

    /**
     * Update the navigation drawer based on current resolution.
     * @param {*} state
     * @param {*} breakpoint
     */
    updateNavigationDrawer(state, breakpoint) {
      state.drawer = breakpoint.lgAndUp ? true : !state.drawer;
      state.mini = breakpoint.lgAndUp ? !state.mini : false;
    },

    /**
     * Set app version.
     * @param {*} state
     * @param {*} version
     */
    setVersion(state, version) {
      state.version = version.split("-")[0];
    },

    /**
     * Fetch configs from server needed for app initialization.
     * @param {*} state
     * @param {*} api
     */
    async fetchInitialData(state, api) {
      const configsResponse = await api.http.get("/backoffice/config");
      state.configs = configsResponse.data;
      state.initialized = true;
    },

    /**
     * Activate or deactivate overlay component.
     * @param {*} state
     * @param {*} overlay
     */
    setOverlayComponent(state, component) {
      state.overlayComponent = component;
    },

    /**
     * Activate or deactivate fullscreen dialog component.
     * @param {*} state
     * @param {*} overlay
     */
    setFullscreenDialogComponent(state, component) {
      state.fullscreenDialogComponent = component;
    },

    /**
     * Set config for fullscreen dialog component.
     * @param {*} state
     * @param {*} overlay
     */
    setFullscreenDialogConfig(state, config) {
      state.fullscreenDialogConfig = config;
    },

    /**
     * Increase state.
     *
     * @param {*} state
     */
    setFullscreenDialogComponentState(state) {
      state.fullscreenDialogComponentState += 1;
    }
  },

  actions: {}
};

export default app;
