<template>
  <div class="login">
    <v-container fluid>
      <v-row justify="center">
        <v-col cols="5" xl="3" class="logo-holder">
          <img src="@/assets/T_RoomManagement_RGB.svg" class="logo" />
        </v-col>
        <v-col cols="5" xl="3">
          <h2>
            Login
          </h2>
          <p class="mt-4">
            Bitte melden Sie sich mit ihrer E-Mail-Adresse und ihrem Passwort
            an.
          </p>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="login()"
          >
            <v-text-field
              outlined
              dense
              label="E-Mail"
              v-model="email"
              :rules="[rules.required, rules.emailRules]"
              :autocomplete="'on'"
            ></v-text-field>
            <v-text-field
              outlined
              dense
              v-model="password"
              :append-icon="passwordVisible ? 'visibility' : 'visibility_off'"
              :rules="[rules.required, rules.min]"
              :type="passwordVisible ? 'text' : 'password'"
              label="Passwort"
              class="input-group--focused"
              @click:append="passwordVisible = !passwordVisible"
              :autocomplete="'on'"
            ></v-text-field>
            <v-btn
              type="submit"
              color="primary"
              elevation="0"
              class="float-right"
            >
              Login
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  data: () => ({
    email: "",
    password: "",
    passwordVisible: false,

    valid: true,

    rules: {
      required: value => !!value || "Muss ausgefüllt werden",
      min: v => v.length >= 8 || "Mindestens 8 Zeichen",
      emailRules: v => /.+@.+\..+/.test(v) || "E-Mail muss gültig sein"
    }
  }),

  methods: {
    /**
     * Login with username and password.
     */
    async login() {
      if (this.$refs.form.validate()) {
        try {
          let response = await this.$api.login(this.email, this.password);
          if (response.status === 200 || response.status === 201) {
            this.$router.push("/");
            return;
          }
          this.$toast.error(response.data);
        } catch (error) {
          this.$toast.error(error);
        }
      }
    },

    /**
     * Login with terminal user.
     */
    async terminalLogin() {
      try {
        let response = await this.$api.terminalLogin();
        if (response.status === 200 || response.status === 201) {
          this.$router.push("/");
          return;
        }
        this.$toast.error(response.data);
      } catch (error) {
        this.$toast.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: relative;

  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .logo-holder {
      position: relative;

      .logo {
        width: 120%;
        position: absolute;
        left: 50%;
        top: 40%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
