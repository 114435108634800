<template>
  <div class="amenities-dialog">
    <v-card class="elevation-0 rounded-0">
      <v-toolbar dark color="primary darken-5" class="elevation-0">
        <v-btn icon dark @click="close()">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>Badges verwalten</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <div style="height:16px;">
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-linear>
        </div>
        <template v-if="!badge">
          <v-row>
            <v-col>
              <v-text-field
                outlined
                dense
                v-model="searchBadgeNumber"
                label="Badgenummer"
                ref="idTextField"
                @keydown.enter="readCard()"
              />
            </v-col>
          </v-row>
          <v-row v-if="showNotFoundMessage">
            <v-col>
              <v-alert color="error" dark>
                Die Badgenummer ist unbekannt oder wurde noch nicht erfasst.
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-if="showNotFoundMessage">
            <v-col></v-col>
            <v-col cols="auto">
              <v-btn @click="close()" large class="elevation-0">
                Abbrechen
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="goToUsers()" large class="elevation-0">
                Zur Benutzerverwaltung
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                @click="openCreateDialog()"
                color="primary"
                large
                class="elevation-0"
              >
                Neuen Benutzer erfassen
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col>
              <h1>{{ badge.first_name }} {{ badge.last_name }}</h1>
              <h2>{{ badge.number }}</h2>
            </v-col>
            <v-col cols="auto">
              <div style="width:100px;">
                <v-switch
                  v-model="isActive"
                  :label="`${isActive ? 'Aktiv' : 'Gesperrt'} `"
                ></v-switch>
              </div>
            </v-col>
            <v-col cols="auto" v-if="badge">
              <tooltip-button
                icon="account_circle"
                text="Benutzer ansehen"
                :color="'default'"
                :dark="false"
                @click="goToUser(badge.user_id)"
                position="top"
                size="medium"
              />
            </v-col>
            <v-col cols="auto">
              <tooltip-button
                icon="save"
                text="Speichern"
                :color="canBeSaved ? 'primary' : 'default'"
                :dark="false"
                @click="save()"
                position="top"
                size="medium"
                :disabled="!canBeSaved"
              />
            </v-col>
          </v-row>
          <v-card class="elevation-0" outlined>
            <v-tabs
              color="primary accent-4"
              left
              background-color="grey lighten-3"
            >
              <v-tab>SIPORT-Dauerprofile</v-tab>
              <v-tab>Derzeit zugewiesen in SIPORT</v-tab>
              <v-tab-item class="px-6">
                <v-row>
                  <v-col v-for="i in [0, 1, 2, 3]" :key="i">
                    <v-checkbox
                      v-for="item in getProfilesForColumn(i)"
                      :key="item.profile"
                      v-model="badge.siport_profiles"
                      :label="`${item.profile} - ${item.name}`"
                      color="primary"
                      :value="item.profile"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-6">
                <v-alert type="info" outlined>
                  Auf Grund des asynchronen Imports aktualisiert sich diese
                  Anzeige nur minütlich. Sollten die vorgenommenen Änderungen
                  also noch nicht ersichtlich sein, schliessen und öffnen Sie
                  das Fenster nochmals.
                </v-alert>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          Profil
                        </th>
                        <th class="text-right">
                          Gültig von
                        </th>
                        <th class="text-right">
                          Gültig bis
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(profiles,
                        index) in badge.assigned_siport_profiles"
                        :key="index"
                      >
                        <td>{{ profiles.profile }}</td>
                        <td class="text-right">
                          {{ profiles.valid_from | formatDateTime }}
                        </td>
                        <td class="text-right">
                          {{ profiles.valid_until | formatDateTime }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </template>
      </v-container>
    </v-card>
    <user-dialog ref="userDialog" @saved="goToUser" />
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import TooltipButton from "@/components/forms/TooltipButton.vue";
import UserDialog from "@/components/dialogs/UserDialog";

export default {
  props: {
    config: {
      type: Object
    }
  },

  components: {
    TooltipButton,
    UserDialog
  },

  data: () => ({
    loading: false,
    badge: null,
    searchBadgeNumber: null,
    searchedBadgeNumber: null,
    dirty: false,
    init: false,
    showNotFoundMessage: false
  }),

  computed: {
    ...mapState("app", ["configs"]),

    availableSiportProfiles() {
      return this.configs.siport_profiles;
    },

    isActive: {
      get() {
        return this.badge && !this.badge.is_locked;
      },
      set(value) {
        this.badge.is_locked = !value;
      }
    },

    canBeSaved() {
      return this.dirty && this.badge;
    }
  },

  watch: {
    badge: {
      handler(value) {
        if (this.init) {
          this.dirty = true;
        }
        this.init = true;
      },
      deep: true
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.config.activeBadge) {
        this.fetchBadgeInfo(this.config.activeBadge);
      }
    });
  },

  methods: {
    ...mapMutations("app", [
      "setFullscreenDialogComponent",
      "setFullscreenDialogConfig",
      "setFullscreenDialogComponentState"
    ]),

    async fetchBadgeInfo(badgeNumber) {
      this.loading = true;
      try {
        const response = await this.$api.http.get(
          `backoffice/badges/${badgeNumber}`
        );
        this.badge = response.data;
        this.loading = false;
      } catch (error) {
        this.showNotFoundMessage = true;
        this.loading = false;
      }
    },

    readCard() {
      this.showNotFoundMessage = false;
      this.fetchBadgeInfo(this.searchBadgeNumber);
      this.searchedBadgeNumber = this.searchBadgeNumber;
      this.searchBadgeNumber = null;
    },

    async save() {
      this.loading = true;
      try {
        const response = await this.$api.http.put(
          `backoffice/badges/${this.badge.number}`,
          this.badge
        );
        this.loading = false;
        this.badge = response.data;
        this.$nextTick(() => {
          this.dirty = false;
        });
        this.setFullscreenDialogComponentState();
      } catch (error) {
        this.$toast.error("Der Badge konnte nicht aktualisiert werden.");
        this.loading = false;
      }
    },

    /**
     * Open create dialog
     */
    openCreateDialog() {
      this.$refs.userDialog.open = true;
      this.$refs.userDialog.user.badge_number = this.searchedBadgeNumber;
    },

    /**
     * Close overlay component.
     */
    close() {
      this.setFullscreenDialogComponent(null);
      this.setFullscreenDialogConfig({});
      this.setFullscreenDialogComponentState();
    },

    /**
     * Go to users overview.
     */
    goToUsers() {
      this.close();
      this.$nextTick(() => {
        this.$router.push("/users");
      });
    },

    /**
     * Go to user edit view.
     */
    goToUser(userId) {
      this.close();
      this.$nextTick(() => {
        this.$router.push(`/users/${userId}`);
      });
    },

    /**
     * Get profiles for a specific column.
     */
    getProfilesForColumn(i) {
      const numPerColumn = Math.ceil(
        Object.values(this.availableSiportProfiles).length / 4
      );
      return Object.values(this.availableSiportProfiles)
        .sort((a, b) => {
          return a.name < b.name ? -1 : 0;
        })
        .filter((k, index) => Math.floor(index / numPerColumn) === i);
    }
  }
};
</script>

<style lang="scss">
.scroll-pane {
  overflow-y: scroll;
}
</style>
