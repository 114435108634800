import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import de from "vuetify/lib/locale/de";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#46b886",
        secondary: "#80ed99",
        accent: "#38a3a5",
        error: "#ff4444",
        info: "#33b5e5",
        success: "#00C851",
        warning: "#ffbb33"
      }
    }
  },
  lang: {
    locales: { de },
    current: "de"
  },
  icons: {
    iconfont: "md"
  }
});
