import axios from "axios";
import store from "../store/index";
import router from "../router";
import VueCookies from "vue-cookies";

class Api {
  /**
   * Create a new Api object
   *
   * @param {string} baseURL
   */
  constructor(baseURL, cookieDomain) {
    this.http = axios;
    this.store = store;
    this.user = null;
    this.cookieDomain = cookieDomain;

    // Set base url
    this.http.defaults.baseURL = baseURL;

    // Handle expired tokens
    this.http.interceptors.response.use(undefined, error => {
      const { config, response, data } = error;
      const originalRequest = config;
      const vm = this;
      return new Promise(function(resolve, reject) {
        if (response && response.status) {
          if (response.status === 401) {
            vm.deleteTokenAndRedirect();
            reject();
          } else if (response.status === 403) {
            router.push("/no-access");
            reject();
          } else if (response.status === 404) {
            //router.push("/not-found");
            reject();
          } else if (response.status === 500) {
            reject();
          } else if (response.status === 400) {
            reject(error);
          }
        }
        resolve(error);
      });
    });

    // Set token from cookie if available
    const token = VueCookies.get("backoffice_token");
    if (token) {
      this.setToken(token);
      this.setUser();
    }
  }

  /**
   * Log in and set token if successful
   *
   * @param {string} email
   * @param {string} password
   */
  login(email, password) {
    return new Promise((resolve, reject) => {
      const data = {
        email,
        password,
        app: "backoffice"
      };
      this.http
        .post("/auth/login", data)
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.setToken(
              response.data.access_token,
              response.data.expires_in + "s"
            );
            this.setUser();
            resolve(response);
            return;
          }
          reject("E-Mail und/oder Passwort falsch.");
        })
        .catch((error, res) => {
          this.deleteTokenAndRedirect();
          reject("E-Mail und/oder Passwort falsch.");
        });
    });
  }

  /**
   * Set auth token in local storage and as authorization header
   *
   * @param {string} token
   */
  setToken(token, ttl = "2592000s") {
    this.http.defaults.headers.common["Authorization"] = "Bearer " + token;
    store.dispatch("user/setToken", token);

    // Store the token as a cookie for SSO purposes if it not already exists.
    if (!VueCookies.get("backoffice_token")) {
      VueCookies.set("backoffice_token", token, ttl, null, this.cookieDomain);
    }
  }

  /**
   * Delete auth token in local storage and as authorization header
   */
  deleteTokenAndRedirect() {
    delete this.http.defaults.headers.common["Authorization"];
    store.dispatch("user/deleteToken");
    VueCookies.remove("backoffice_token", "/", this.cookieDomain);
    router.push("/login").catch(err => {});
  }

  /**
   * Fetch user from Me endpoint
   */
  setUser() {
    this.http
      .get("/backoffice/me")
      .then(response => {
        this.user = response.data;
        store.dispatch("user/setUser", response.data);
        store.commit("app/fetchInitialData", this);
      })
      .catch(error => {
        this.$toast.error("Der Benutzer konnte nicht authentifiziert werden.");
      });
  }

  /**
   * Return logged in user
   */
  getUser() {
    return this.user;
  }

  /**
   * Check if a token is set
   */
  isLoggedIn() {
    return VueCookies.get("backoffice_token") || false;
  }

  /**
   * Delete token and authorization header
   */
  logout() {
    this.http
      .post("/auth/logout")
      .then(response => {
        this.deleteTokenAndRedirect();
      })
      .catch(error => {
        this.$toast.error("Der Benutzer konnte nicht ausgeloggt werden.");
      });
  }
}

export default Api;
