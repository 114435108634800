<template>
  <div class="date-picker">
    <v-menu
      v-model="open"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :outlined="outlined"
          :dense="dense"
          v-model="dateFormatted"
          :label="label"
          append-icon="event"
          readonly
          :clearable="clearable"
          v-on="on"
          @click:append="openPicker"
          @click:clear="date = null"
          :disabled="disabled"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :type="type"
        first-day-of-week="1"
        @input="open = false"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    value: {
      required: true
    },
    label: {
      default: "Datum",
      type: String
    },
    outlined: {
      default: true
    },
    dense: {
      default: true
    },
    format: {
      type: String,
      default: "DD.MM.YYYY"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "date"
    },
    clearable: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    open: false
  }),

  computed: {
    date: {
      get() {
        return this.value ? this.value.format("YYYY-MM-DD") : null;
      },
      set(value) {
        this.$emit("input", value ? moment(value) : null);
      }
    },

    dateFormatted: {
      get() {
        return this.value ? this.value.format(this.format) : "-";
      },
      set(value) {}
    }
  },

  methods: {
    openPicker() {
      this.open = !this.open;
    }
  }
};
</script>

<style></style>
