import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Api from "./services/api";
import Permissions from "./services/permissions";
import Pusher from "pusher-js";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./filters/dateFilters";
import "./filters/unitFilters";

Vue.config.productionTip = false;

// Define $api instance property
Vue.prototype.$api = new Api(
  process.env.VUE_APP_API_URL,
  process.env.VUE_APP_COOKIE_DOMAIN
);

// Define $permissions instance property
Vue.prototype.$permissions = new Permissions();

// Define $pusher instance property
Vue.prototype.$pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {
  cluster: process.env.VUE_APP_PUSHER_CLUSTER,
  forceTLS: false,
  disableStats: true
});

Vue.use(Toast, {
  timeout: 4000,
  position: POSITION.BOTTOM_CENTER,
  transition: "Vue-Toastification__fade",
});

new Vue({
  router,
  store,
  vuetify,
  async created() {
    // Watch for settings changed events.
    const channel = this.$pusher.subscribe("frontend-channel");
    channel.bind("settings-updated-event", () => {
      if (this.$api.isLoggedIn) {
        this.$store.commit("app/fetchInitialData", this.$api);
      }
    });
    channel.bind("config-updated-event", () => {
      if (this.$api.isLoggedIn) {
        this.$store.commit("app/fetchInitialData", this.$api);
      }
    });
  },
  render: h => h(App)
}).$mount("#app");
