<template>
  <v-app-bar
    app
    dark
    elevation="0"
    v-if="isLoggedIn && !isFullscreen"
    class="accent-gradient"
  >
    <v-spacer></v-spacer>

    <div class="logged-in-user caption mt-1 mr-4" v-if="user">
      {{ user.name }}
    </div>

    <v-tooltip bottom v-if="$permissions.hasPermission('logs')">
      <template v-slot:activator="{ on }">
        <v-btn icon to="/logs/system" v-on="on">
          <v-icon>poll</v-icon>
        </v-btn>
      </template>
      <span>Logs</span>
    </v-tooltip>
    <v-tooltip bottom v-if="$permissions.hasPermission('settings')">
      <template v-slot:activator="{ on }">
        <v-btn icon to="/settings" v-on="on">
          <v-icon>settings_applications</v-icon>
        </v-btn>
      </template>
      <span>Einstellungen</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn icon @click="logout" v-on="on">
          <v-icon>exit_to_app</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  components: {},

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["isLoggedIn"]),

    isFullscreen() {
      return this.$route.meta.isFullscreen ?? false;
    }
  },

  methods: {
    logout() {
      this.$api.logout();
    }
  }
};
</script>

<style lang="scss" scoped>
.logged-in-user {
  line-height: 0.8em;
}
.accent-gradient {
  background: linear-gradient(to right, #2a3636, #091d1d);
}
</style>
