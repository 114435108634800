<template>
  <v-app id="app">
    <template v-if="initialized || isLoginPage || isNoAccessPage">
      <app-navigation-drawer />
      <v-main>
        <app-bar />
        <router-view></router-view>
      </v-main>
      <app-overlay />
      <app-fullscreen-dialog />
    </template>
    <template v-else>
      <app-loader />
    </template>
  </v-app>
</template>

<script>
import AppBar from "@/components/app/AppBar";
import AppNavigationDrawer from "@/components/app/AppNavigationDrawer";
import AppOverlay from "@/components/app/AppOverlay";
import AppFullscreenDialog from "@/components/app/AppFullscreenDialog";
import AppLoader from "@/components/app/AppLoader";
import version from "../version.json";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    AppBar,
    AppNavigationDrawer,
    AppLoader,
    AppOverlay,
    AppFullscreenDialog
  },

  created() {
    // Read version from version.json
    this.setVersion(version);
  },

  computed: {
    ...mapState("app", ["initialized"]),

    isLoginPage() {
      return this.$route.name === "login";
    },

    isNoAccessPage() {
      return this.$route.name === "no-access";
    }
  },

  methods: {
    ...mapMutations("app", ["setVersion"])
  }
};
</script>

<style lang="scss">
.v-btn {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.v-tab.v-tab {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.theme--light.v-card {
  /* background: linear-gradient(180deg, #f1f1f1, white); */
}

.theme--light.v-sheet--outlined {
  /* border: none !important; */
}

.theme--light.v-data-table {
  /* background: transparent !important; */
}
</style>
