<template>
  <v-dialog
    :value="fullscreenDialogComponent"
    fullscreen
    hide-overlay
    persistent
    transition="dialog-bottom-transition"
  >
    <component
      :is="fullscreenDialogComponent"
      :config="fullscreenDialogConfig"
    ></component>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["fullscreenDialogComponent", "fullscreenDialogConfig"])
  }
};
</script>

<style>
.v-dialog--fullscreen {
  background: white;
  /* height: auto; */
}
</style>
