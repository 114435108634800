<template>
  <v-overlay :value="!!overlayComponent" class="app-overlay" :dark="false">
    <component :is="overlayComponent"></component>
  </v-overlay>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("app", ["overlayComponent"])
  }
};
</script>

<style>
.app-overlay .v-overlay__content {
  width: 80vw;
  height: 90vh;
  overflow-y: scroll;
  background-color: rgba(255, 255, 255);
  padding: 1% 2%;
}
</style>
