import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store/index";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// Validate meta fields (authentication rules)
router.beforeEach((to, from, next) => {
  // Check if authentication is required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters["user/isLoggedIn"]) {
      next();
      return;
    }
    next("/login");
    return;
  }

  // Check if a redirection is expected
  if (to.matched.some(record => record.meta.redirectAfterAuth)) {
    if (store.getters["user/isLoggedIn"]) {
      next("/");
    }
  }
  next();
});

export default router;
