const importer = {
  namespaced: true,

  state: {
    step: 1,
    tenantId: null,
    tenantName: null,
    validationHeaders: [],
    validationData: [],
    isValid: false,
    siportProfiles: [],
    file: null,
    sendMails: true
  },

  getters: {},

  mutations: {
    nextStep(state) {
      state.step = state.step < 5 ? state.step + 1 : 5;
    },

    prevStep(state) {
      state.step = state.step > 1 ? state.step - 1 : 1;
    },

    reset(state) {
      state.step = 1;
      state.tenantId = null;
      state.tenantName = null;
      state.validationHeaders = [];
      state.validationData = [];
      state.isValid = false;
      state.file = null;
      state.siportProfiles = [];
      state.sendMails = true;
    },

    setFile(state, file) {
      state.file = file;
    },

    setTenantName(state, tenantName) {
      state.tenantName = tenantName;
    },

    setTenantId(state, tenantId) {
      state.tenantId = tenantId;
    },

    setValidationHeaders(state, validationHeaders) {
      state.validationHeaders = validationHeaders;
    },

    setValidationData(state, validationData) {
      state.validationData = validationData;
    },

    setIsValid(state, isValid) {
      state.isValid = isValid;
    },

    setSiportProfiles(state, siportProfiles) {
      state.siportProfiles = siportProfiles;
    },

    setSendMails(state, sendMails) {
      state.sendMails = sendMails;
    }
  },

  actions: {}
};

export default importer;
