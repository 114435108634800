import Vue from "vue";
import moment from "moment";

Vue.filter("formatTime", function(value) {
  if (value) {
    return moment(value).format("HH:mm");
  }
});

Vue.filter("formatTimeShort", function(value) {
  if (value) {
    return moment(value).format("HH:mm");
  }
});

Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(value).format("DD.MM.YYYY");
  }
});

Vue.filter("formatDateTime", function(value) {
  if (value) {
    return moment(value).format("DD.MM.YYYY HH:mm:ss");
  }
});
