<template>
  <v-tooltip :top="top" :right="right" :bottom="bottom" :left="left">
    <template v-slot:activator="{ on }">
      <v-btn
        :color="color"
        :dark="dark"
        fab
        v-on="on"
        @click="$emit('click')"
        :class="classNames"
        :small="small"
        :x-small="xSmall"
        :medium="medium"
        :large="large"
        :x-large="xLarge"
        :disabled="disabled"
        :to="to"
        :absolute="absolute"
        :top="floatTop"
        :right="floatRight"
        :bottom="floatBottom"
        :left="floatLeft"
      >
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    dark: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: "medium"
    },
    position: {
      type: String,
      default: "bottom"
    },
    classNames: {
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: null
    },
    float: {
      type: String,
      default: ""
    }
  },

  computed: {
    small() {
      return this.size === "small";
    },
    xSmall() {
      return this.size === "x-small";
    },
    medium() {
      return this.size === "medium";
    },
    large() {
      return this.size === "large";
    },
    xLarge() {
      return this.size === "x-large";
    },
    top() {
      return this.position === "top";
    },
    right() {
      return this.position === "right";
    },
    bottom() {
      return this.position === "bottom";
    },
    left() {
      return this.position === "left";
    },
    absolute() {
      return !!this.float;
    },
    floatTop() {
      return this.float && this.float.includes("top");
    },
    floatBottom() {
      return this.float && this.float.includes("bottom");
    },
    floatLeft() {
      return this.float && this.float.includes("left");
    },
    floatRight() {
      return this.float && this.float.includes("right");
    }
  }
};
</script>

<style></style>
