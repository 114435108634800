<template>
  <v-dialog v-model="open" scrollable width="800">
    <v-card v-if="user">
      <v-card-title class="primary white--text">
        Benutzer erfassen
        <template v-if="selectedUserTypeName">
          &nbsp;({{ selectedUserTypeName }})
        </template>
      </v-card-title>

      <v-card-text class="mt-3">
        <v-stepper v-model="step" class="elevation-0">
          <v-stepper-header class="elevation-0">
            <v-stepper-step :complete="step > 1" step="1">
              Typ
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2">
              Basisdaten
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3">
              Badge
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="4">
              Erweiterte Daten
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="elevation-0">
                <v-select
                  outlined
                  dense
                  v-model="userType"
                  :items="availableTypes"
                  item-text="text"
                  item-value="value"
                  label="Typ"
                ></v-select>
                <v-card-actions class="pa-0 mt-6">
                  <v-btn @click="abort()" class="elevation-0">
                    Abbrechen
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="elevation-0"
                    color="primary"
                    @click="step = 2"
                    :disabled="!userType"
                  >
                    Weiter
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="elevation-0">
                <v-form v-model="validBasic">
                  <v-select
                    outlined
                    dense
                    :items="genders"
                    v-model="user.gender"
                    label="Geschlecht"
                    item-text="name"
                    item-value="value"
                  ></v-select>
                  <v-text-field
                    outlined
                    dense
                    v-model="user.first_name"
                    label="Vorname"
                    type="text"
                    :maxlength="255"
                    :rules="textRules"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="user.last_name"
                    label="Nachname"
                    type="text"
                    :maxlength="255"
                    :rules="textRules"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="user.email"
                    label="E-Mail"
                    type="text"
                    :maxlength="255"
                    :rules="emailRules"
                  ></v-text-field>
                  <v-alert v-if="!validEmail" dense dark color="warning">
                    <v-row>
                      <v-col>
                        <div class="mt-1">
                          Die E-Mail-Adresse muss validiert werden.
                        </div>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          @click="validateEmail()"
                          color="warning darken-2"
                          small
                          class="elevation-0"
                          :disabled="!validBasic"
                        >
                          Validieren
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-form>
                <v-card-actions class="pa-0 mt-6">
                  <v-btn @click="step = 1" class="elevation-0">
                    Zurück
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="step = 3"
                    class="elevation-0"
                    :disabled="!validBasic || !validEmail"
                  >
                    Weiter
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="elevation-0">
                <v-form v-model="validBadge">
                  <v-text-field
                    outlined
                    dense
                    v-model="user.badge_number"
                    label="Badgenummer"
                    type="text"
                    :maxlength="255"
                    :rules="badgeNumberRules"
                  ></v-text-field>
                  <v-alert v-if="!validBadgeNumber" dense dark color="warning">
                    <v-row>
                      <v-col>
                        <div class="mt-1">
                          Die Badgenummer muss validiert werden.
                        </div>
                      </v-col>
                      <v-col cols="auto">
                        <v-btn
                          @click="validateBadgeNumber()"
                          color="warning darken-2"
                          small
                          class="elevation-0"
                          :disabled="!validBadge"
                        >
                          Validieren
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </v-form>
                <v-card-actions class="pa-0 mt-6">
                  <v-btn @click="step = 2" class="elevation-0">
                    Zurück
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="step = 4"
                    class="elevation-0"
                    :disabled="!validBadge || !validBadgeNumber"
                  >
                    Weiter
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card class="elevation-0">
                <v-form v-model="validExtended">
                  <v-select
                    outlined
                    dense
                    :items="availableTenants"
                    v-model="user.tenant_id"
                    label="Mandant"
                    item-text="name"
                    item-value="value"
                    :rules="textRules"
                  ></v-select>
                  <v-text-field
                    outlined
                    dense
                    v-model="user.cost_center"
                    label="Kostenstelle"
                    type="text"
                    :maxlength="255"
                  ></v-text-field>
                  <v-text-field
                    outlined
                    dense
                    v-model="user.department"
                    label="Abteilung"
                    type="text"
                    :maxlength="255"
                  ></v-text-field>
                </v-form>
                <v-card-actions class="pa-0 mt-6">
                  <v-btn @click="step = 3" class="elevation-0">
                    Zurück
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="save()"
                    class="elevation-0"
                    :disabled="!validExtended"
                  >
                    Erfassen
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data: () => ({
    open: false,
    step: 1,
    user: {},
    userType: null,
    availableTypes: [
      {
        text: "Mieter",
        value: "inhouse"
      },
      {
        text: "Backoffice",
        value: "backoffice"
      },
      {
        text: "Ohne Berechtigungen",
        value: "no-permission"
      }
    ],
    validBasic: false,
    validExtended: false,
    validBadge: false,
    availableTenants: [],
    loading: false,
    textRules: [v => !!v || "Muss ausgefüllt sein"],
    emailRules: [
      v =>
        (!!v &&
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          )) ||
        "Ungültige E-Mail."
    ],
    badgeNumberRules: [
      v => (!!v && /^\d+$/.test(v)) || "Ungültige Badgenummer."
    ],
    validEmail: false,
    validBadgeNumber: false
  }),

  computed: {
    ...mapState("app", ["configs"]),

    selectedUserTypeName() {
      return this.userType
        ? this.availableTypes.find(at => at.value === this.userType).text
        : "";
    },

    genders() {
      return Object.keys(this.configs.genders).map(key => ({
        name: this.configs.genders[key],
        value: key
      }));
    }
  },

  watch: {
    open(value) {
      if (value && this.availableTenants.length === 0) {
        this.fetchTenants();
      }
    }
  },

  methods: {
    /**
     * Fetch tenants from server
     */
    fetchTenants() {
      this.$api.http
        .get(`backoffice/tenants?_per_page=99999`)
        .then(response => {
          this.availableTenants = response.data.data.map(tenant => {
            return {
              name: `${tenant.name}`,
              value: tenant.id
            };
          });
        });
    },

    /**
     * Validate the email.
     */
    validateEmail() {
      this.$api.http
        .post(`backoffice/validate/user.email`, { value: this.user.email })
        .then(response => {
          this.validEmail = true;
        })
        .catch(error => {
          this.validEmail = false;
          this.$toast.error(
            "Es existiert bereits ein Benutzer mit dieser Email."
          );
        });
    },

    /**
     * Validate the badge number.
     */
    validateBadgeNumber() {
      this.$api.http
        .post(`backoffice/validate/user.badge_number`, {
          value: this.user.badge_number
        })
        .then(response => {
          this.validBadgeNumber = true;
        })
        .catch(error => {
          this.validBadgeNumber = false;
          this.$toast.error(
            "Es existiert bereits ein Benutzer mit dieser Badgenummer."
          );
        });
    },

    /**
     * Abort.
     */
    abort() {
      this.open = false;
      this.user = {};
      this.$emit("aborted");
    },

    /**
     * Store user.
     */
    save() {
      this.loading = true;
      this.user.type = this.userType;
      this.$api.http
        .post(`backoffice/users`, this.user)
        .then(response => {
          this.loading = false;
          this.open = false;
          this.$emit("saved", response.data.id);
        })
        .catch(error => {
          this.loading = false;
          if (error.response.data.errors.email) {
            this.$toast.error(
              "Es existiert bereits ein Benutzer mit dieser E-Mail."
            );
          } else if (error.response.data.errors.badge_number) {
            this.$toast.error(
              "Es existiert bereits ein Benutzer mit dieser Badgenummer."
            );
          } else {
            this.$toast.error("Der Benutzer konnte nicht eröffnet werden.");
          }
        });
    }
  }
};
</script>

<style lang="scss"></style>
